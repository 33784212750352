<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0">
              <strong>Create Publisher</strong>
            </div>
            <div class="text-muted">Create new publisher</div>
          </div>
          <div class="card-body">
            <a-form :form="form" @submit.prevent="handleSubmit">
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Name">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Publisher's name.</span>
                  </template>
                  <a-input type="text" placeholder="Publisher Name" v-decorator="[ 'name', {
                  rules: [
                    { required: true, message: 'Name is required.' },
                    { max: 64, message: 'Maximum 64 characters allowed.'}
                    ]}]"/>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Email">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Publisher's e-mail address.</span>
                  </template>
                  <a-input type="email" placeholder="Publisher Email Address"
                           v-decorator="[ 'email', {
                           rules: [
                             { required: true, message: 'Email is not valid.', pattern: emailPattern },
                             { max: 64, message: 'Maximum 64 characters allowed.'}
                             ]}]"/>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Username">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Publisher's username. It is required for fetching statistics through stats API or CSV export.</span>
                  </template>
                  <a-input type="text" placeholder="Publisher Username"
                           v-decorator="[ 'username', {
                           rules: [
                             { required: true, message: 'Username is required.' },
                             { pattern: usernameRegExp, message: 'Spaces not allowed in username.'},
                             { max: 32, message: 'Maximum 32 characters allowed.'}
                             ]}]"/>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Country">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Publisher's country.</span>
                  </template>
                  <a-select
                    v-decorator="['country', {rules: [{ required: true, message: 'Country is required.' }]}]"
                    placeholder="Select a country"
                    :showSearch="true"
                    :filterOption="countryFilter"
                    optionFilterProp="children"
                  >
                    <a-select-option v-for="obj in info.countries" :key="obj.CC"><img :src="matchIcon(countries, obj.CC)" /> {{ obj.Country }}</a-select-option>
                  </a-select>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Description">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Publisher's description.</span>
                  </template>
                  <a-input placeholder="Publisher Description" v-decorator="['description', {
                  rules: [
                             { max: 512, message: 'Maximum 512 characters allowed.'}
                         ]
                }]"/>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Website">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Publisher's website.</span>
                  </template>
                  <a-input placeholder="Publisher Website" v-decorator="['website', {
                  rules: [
                             { max: 64, message: 'Maximum 64 characters allowed.'}
                         ]
                }]"/>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="City">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Publisher's city.</span>
                  </template>
                  <a-input placeholder="City" v-decorator="[ 'city', { initialValue: null,
                 rules: [
                             { max: 20, message: 'Maximum 20 characters allowed.'}
                         ]}]"/>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Zip Code">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Publisher's zip code.</span>
                  </template>
                  <a-input type="number" placeholder="Zip Code" v-decorator="[ 'zipCode', { initialValue: null,
                rules: [{ transform: function(value) { return Number(value)}, type: 'number' }] }]"/>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Address">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Publisher's address.</span>
                  </template>
                  <a-input placeholder="Address" v-decorator="[ 'address', { initialValue: null, rules: [
                             { max: 64, message: 'Maximum 64 characters allowed.'}
                         ]}]"/>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Phone">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Publisher's phone number.</span>
                  </template>
                  <a-input @keydown="phoneRegExp($event)" placeholder="Phone" v-decorator="[ 'phone', { initialValue: null,
                 rules: [
                   { message: 'Invalid phone number.' },
                   { max: 20, message: 'Maximum 20 characters allowed.'}
                   ]}]"/>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Skype">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Publisher's Skype username.</span>
                  </template>
                  <a-input placeholder="Skype" v-decorator="[ 'skype', { initialValue: null,
                 rules: [
                             { max: 32, message: 'Maximum 32 characters allowed.'}
                         ]}]"/>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Platform Domain">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>The canonical domain name of the advertising system of the publisher. RTB Zone of the publisher will try to inherit this value on creation.</span>
                  </template>
                  <a-input type="text"
                          v-decorator="['platformDomain', { rules: [{ max: 32, message: 'Maximum 32 characters allowed.'},
                          { pattern: domainRegExp, message: '\'www.\', \'http://\' and \'https://\' are not allowed. Please input pure top-level domain, for example: google.com'}]}]"
                          placeholder="Publisher Platform Domain"/>
                </a-tooltip>
              </a-form-item>

              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="sellers.json Domain">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>The canonical domain where advertising system of the publisher hosts its sellers.json file. Usually it is the same domain as Platform Domain. RTB Zone of the publisher will try to inherit this value on creation.</span>
                  </template>
                  <a-input type="text"
                          v-decorator="['sellersJsonDomain', { rules: [{ max: 32, message: 'Maximum 32 characters allowed.'},
                          { pattern: domainRegExp, message: '\'www.\', \'http://\' and \'https://\' are not allowed. Please input pure top-level domain, for example: google.com'}]}]"
                          placeholder="sellers.json Domain"/>
                </a-tooltip>
              </a-form-item>
              <a-divider class="mt-3"></a-divider>
              <div class="ant-row ant-form-item">
                <div class="ant-col ant-col-xs-24 ant-col-sm-3 ant-form-item-label"></div>
                <div class="ant-col ant-col-xs-24 ant-col-sm-18 ant-form-item-control-wrapper">
                  <button type="submit" class="btn btn-success px-5">Create Publisher</button>
                  <button type="button" @click="$router.push({ name: 'publishers' })"
                          class="btn btn-light px-5 ml-2">Cancel
                  </button>
                </div>
              </div>
            </a-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import staticCountryData from '@/resources/countries.json'

export default {
  computed: {
    ...mapState(['info']),
  },
  data() {
    return {
      domainRegExp: null,
      usernameRegExp: null,
      countries: staticCountryData,
      emailPattern: '(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])',
      form: this.$form.createForm(this),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 3 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    }
  },
  methods: {
    countryFilter(inputValue, option) {
      return option.componentOptions.children[1].text.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
    },
    matchIcon(array, code) {
      const cc = array.find(element => element.cc === code)
      if (cc === undefined) { return false } else {
        const image = cc.image
        return image
      }
    },
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$store.dispatch('ORTBpublisher/CREATE_PUBLISHER', {
            payload: values,
            callback: function () {
              this.$router.push({ name: 'publishers' })
            }.bind(this),
          })
        }
      })
    },
    phoneRegExp(e) {
      if ((/^[0-9,+]$/.test(e.key) && /^\+?\d*$/.test(e.target.value + e.key)) || e.key === 'Backspace') {
        return true
      } else {
        e.preventDefault()
      }
    },
  },
  created() {
    this.$store.dispatch('info/LOAD_COUNTRIES')
    this.domainRegExp = new RegExp(/^(?!https?:\/\/|www\.).*$/)
    this.usernameRegExp = new RegExp(/^\S*$/)
  },
}
</script>
<style lang="css" scoped>
.ant-form-item {
  margin-bottom: 5px !important;
}
</style>
